import { Component, OnInit } from '@angular/core';
import { NgForm, UntypedFormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { DatePipe, formatDate } from '@angular/common';

import { JobCategory } from '../../../appshared/masters/job-category/job-category.module';
import { JobCategoryService } from '../../../appshared/masters/job-category/job-category.service';
import { VisaTypeModule } from '../../../appshared/visa/visa-type/visa-type.module';
import { VisaTypeService } from '../../../appshared/visa/visa-type/visa-type.service';
import { FailedDialogComponent } from '../step1-visa-type/failed-dialog/failed-dialog.component';
import { VisaCountryService } from '../../../appshared/visa/visa-country/visa-country.service';
import { VisaCountry, VisaCountryModule } from '../../../appshared/visa/visa-country/visa-country.module';
import { AppLoaderService } from '../../../common/app-loader/app-loader.service';
import { SavedOrderService } from '../../../appshared/tools/saved-order/saved-order.service';
import { InputInfoDialogComponent } from '../step3-personal-details/input-info-dialog/input-info-dialog.component';
import { InfoDialogComponent } from './info-dialog/info-dialog.component';

@Component({
    selector: 'app-step2-basic-details',
    templateUrl: './step2-basic-details.component.html',
    styleUrls: ['./step2-basic-details.component.scss'],
    providers: [DatePipe]
})
export class Step2BasicDetailsComponent implements OnInit {

    constructor(public dialog: MatDialog, private serv: VisaTypeService, private router: Router, private route: ActivatedRoute, public translate: TranslateService, private servJobCategory: JobCategoryService, private toastr: ToastrService, private datePipe: DatePipe, public servVisaCountry: VisaCountryService, private loader: AppLoaderService, private ServSavedOrder: SavedOrderService) {
        if (this.translate.currentLang != undefined) {

            this.SelectedLanguage = this.translate.currentLang;
        }
    }

    public dataSource = new MatTableDataSource<VisaTypeModule>();

    public SelectedLanguage: string = "en";
    public SelectedCurrID: number = 1;
    private sub: any;
    vtid: number = 0;
    nid: number = 0;
    vid: number = 0;
    rid: number = 0;
    dates: string = "";

    dialingCode: string = "+968";
    countryName: string = "";
    stayDays: string = "";
    fromDate = new Date();
    toDate = new Date();


    warningMessage: string = "";
    warningAgeMessage: string = "";
    warningGenderMessage: string = "";

    isJob: boolean = false;
    data: any = {};

    protected _onDestroy = new Subject<void>();

    ngOnInit(): void {
        this.sub = this.route.params.subscribe(params => {
            this.vtid = +params['vtid'];
            this.nid = +params['nid'];
            this.vid = +params['vid'];
            this.rid = +params['rid'];
            this.dates = params['date'];
            this.FillVisaTypes();
            this.CheckAgeWarnings();
            this.CheckGenderWarnings();
        });

        this.jobCategoryFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.filterjobCategories();
            });
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    private datas: any;
    FillVisaTypes() {

        this.loader.open();
        this.serv.getServiceId(this.vtid)
            .subscribe(res => {
                this.datas = res as VisaTypeModule[];



                this.serv.formData.visaTypeID = this.datas.visaTypeID;
                this.serv.formData.nameAr = this.datas.nameAr;
                this.serv.formData.nameEn = this.datas.nameEn;
                this.serv.formData.notes = this.datas.notes;
                this.serv.formData.isVisible = this.datas.isVisible;
                this.serv.formData.visaCountryID = this.datas.visaCountryID;
                this.serv.formData.isJob = this.datas.isJob;
                this.serv.formData.orderPeriod = this.datas.orderPeriod;
                this.isJob = this.datas.isJob;


                this.serv.formData.visaPeriod = this.datas.visaPeriod;
                this.serv.formData.visaValidPeriod = this.datas.visaValidPeriod;

                this.stayDays = this.serv.formData.visaPeriod.toString();



                const [day, month, year] = this.dates.split('/');

                this.fromDate = new Date(+year, +month - 1, +day);
                this.toDate = new Date(this.fromDate.getFullYear(), this.fromDate.getMonth(), this.fromDate.getDate())
              
                var perd = (this.serv.formData.visaValidPeriod - 1);
                this.toDate.setDate(this.toDate.getDate() + perd);
              
                if (this.SelectedLanguage == "en") {
                    this.warningMessage = "Your Visa valid from " + this.datePipe.transform(this.fromDate, 'dd/MM/yyyy') + " to " + this.datePipe.transform(this.toDate, 'dd/MM/yyyy') + ". Your stay cannot exceed " + this.stayDays + " days.";
                }
                else {
                    this.warningMessage = "صلاحية التأشيرة من تاريخ " + this.datePipe.transform(this.fromDate, 'dd/MM/yyyy') + " إلى تاريخ " + this.datePipe.transform(this.toDate, 'dd/MM/yyyy') + ". مدة الإقامة لا تتجاز" + this.stayDays + " يوم. ";
                }

                this.getVisaCountry(this.serv.formData.visaCountryID);

                //if (this.SelectedLanguage == "en") {
                //    this.warningMessage = "Your Visa valid from " + formatDate(Date.now(),'dd/MM/yyyy',) + " to " + this.datepipe.transform(this.toDate, 'dd/MM/yyyy') +". Your stay cannot exceed 90 days.";
                //}
                //else {
                //    this.warningMessage = "صلاحية التأشيرة من تاريخ " + this.datepipe.transform(this.fromDate, 'dd/MM/yyyy') + " إلى تاريخ " + this.datepipe.transform(this.toDate, 'dd/MM/yyyy') + ". مدة الإقامة هي" + this.stayDays + " يوم. ";
                //}


                if (this.serv.formData.isJob) {
                    this.getAlljobCategory();
                }
                this.loader.close();
            },
                err => {
                    this.loader.close(); console.log(err);
                });
    }

    private CheckAgeWarnings() {
        this.serv.getCheckAgeWarnings(this.vtid, this.nid, this.SelectedLanguage)
            .subscribe(res => {
                this.warningAgeMessage = res;

            },
                err => {
                });
    }

    private CheckGenderWarnings() {
        this.serv.getCheckGenderWarnings(this.vtid, this.nid, this.SelectedLanguage)
            .subscribe(res => {
                this.warningGenderMessage = res;

            },
                err => {
                });
    }


    // *******************************************************************************************************************************************

    public jobCategories: JobCategory[];



    public jobCategoryCtrl: UntypedFormControl = new UntypedFormControl();
    public jobCategoryFilterCtrl: UntypedFormControl = new UntypedFormControl();
    public filteredJobCategories: ReplaySubject<JobCategory[]> = new ReplaySubject<JobCategory[]>(1);

    protected filterjobCategories() {
        if (!this.jobCategories) {
            return;
        }
        // get the search keyword
        let search = this.jobCategoryFilterCtrl.value;
        if (!search) {
            this.filteredJobCategories.next(this.jobCategories.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the nobCategories
        this.filteredJobCategories.next(
            this.jobCategories.filter(JobCategory => JobCategory.nameEn.toLowerCase().indexOf(search) > -1)
        );
    }


    public getAlljobCategory = () => {

        this.servJobCategory.getService()
            .subscribe(res => {
                //this.nobCategories = res as jobCategoryModule[];
                this.jobCategories = res as JobCategory[];

                this.filteredJobCategories.next(this.jobCategories.slice());

            })
    }


    private datas2: any;
    getVisaCountry(countryID: number) {

        this.servVisaCountry.getServiceId(countryID)
            .subscribe(res => {
                this.datas2 = res as VisaCountryModule[];
                if (this.SelectedLanguage == "en") {
                    this.countryName = this.datas2.nameEn;
                }
                else {
                    this.countryName = this.datas2.nameAr;
                }
               
            })

        // this.countryName
    }

    // *******************************************************************************************************************************************

    checkMessage: string = "";
    onClick(jobCategorySelect, email, confirmEmail, arrivalPort, phoneNo, applicantRequesterName, dialing) {


        this.scrollToTop();

        if (email == "" || confirmEmail == "" || arrivalPort == "" || phoneNo == "" ||  applicantRequesterName == "" || (this.serv.formData.isJob && jobCategorySelect == "")) {
            this.toastr.error('Please Fill All Data', '');
            return;
        }
        if (!this.validateEmail(email)) {
            return;
        }
        if (email != confirmEmail) {
            this.toastr.error('Your Email not matching', '');
            return;
        }
        if (jobCategorySelect == "") {
            jobCategorySelect = "0";
        }

        this.loader.open();
        this.serv.getInsertOrder(this.vtid, this.vid, this.nid, this.rid, this.dates, this.SelectedLanguage, this.SelectedCurrID, email, arrivalPort, phoneNo, applicantRequesterName, '+' + this.Dialing)
            .subscribe(res => {
                this.checkMessage = res;
                
                if (this.isNumeric(this.checkMessage)) {
                   

                    this.toastr.success('Saved Successfully', '');



                    this.ServSavedOrder.setNewOrder(this.vtid, this.nid, this.vid, this.rid, +this.checkMessage, email, this.dates ,0,'Draft');


                    this.router.navigate(['step3', { vtid: this.vtid, nid: this.nid, rid: this.rid, date: this.dates, vid: this.vid, oid: this.checkMessage, email: email }]);
                }
                else {


                    let title = 'create';
                    let dialogRef: MatDialogRef<any> = this.dialog.open(FailedDialogComponent, {
                        width: '720px',
                        disableClose: true,
                        data: { title: title, payload: this.data, messageTXT: this.checkMessage }
                    })
                    dialogRef.afterClosed()
                        .subscribe(res => {
                            if (!res) {
                                return;
                            }

                            this.router.navigate(['home']);

                        })
                }

                this.loader.close();
            },
                err => {
                    this.loader.close(); console.log(err);
                });
    }
     isNumeric(value) {
    return /^\d+$/.test(value);
}


    validateEmail(email) {
        var emailTest = true;
        if (email != '') {
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            console.log(re.test(String(email).toLowerCase()))
            emailTest = re.test(String(email).toLowerCase())
            if (emailTest != true) {
                this.toastr.warning("Please enter valid email")
          
            }

        }
        return emailTest;
    }

    scrollToTop() {
        window.scroll(0, 0);
        document.body.scrollTop = 0;
    }


    viewDialog(stringValue) {

        let title = 'create';
        let dialogRef: MatDialogRef<any> = this.dialog.open(InfoDialogComponent, {
            width: '720px',
            disableClose: true,
            data: { title: title, id: this.vid, code: stringValue }
        })
        dialogRef.afterClosed()
            .subscribe(res => {
                if (!res) {
                    return;
                }
            });
    }



    //////////////////////////////////////////////////////////////////////////

    Dialing: string = "968";
    telOptions = { initialCountry: 'om', preferredCountries: ['om'] };
    phone_number_standard = '';
    onCountryChange(event) {
        this.Dialing = event.dialCode;
    }


}


